.markedSliderWrap {
    padding: 0px 20px 50px;
}

.rangeSliderWrap {
    padding: 0 0 20px 0;
}

.markedSliderVerticalWrap {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 30px 20px;
}

.inlineInput {
    max-width: 70px;
    margin-left: 0.5rem;
}
